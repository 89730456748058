import { User } from "modules";
import { getUserAsync, getUsersAsync, GET_USER, GET_USERS } from ".";
import { call, delay, put, takeEvery } from "@redux-saga/core/effects";
import {
  getUserById,
  getUsers,
  postUserRegister,
  postUserLogin,
} from "api/users";
import {
  postUserAsync,
  postUserLoginAsync,
  POST_USER,
  POST_USER_LOGIN,
} from "./users";

function* getUsersSaga(action: ReturnType<typeof getUsersAsync.request>) {
  try {
    const users: User[] = yield call(getUsers);
    yield put(getUsersAsync.success(users));
  } catch (error: any) {
    yield put(getUsersAsync.failure(error));
  }
}

function* getUserSaga(action: ReturnType<typeof getUserAsync.request>) {
  try {
    const user: User = yield call(getUserById, action.payload); //payload : id
    yield put(getUserAsync.success(user));
  } catch (error: any) {
    yield put(getUserAsync.failure(error));
  }
}

function* postUserSaga(action: ReturnType<typeof postUserAsync.request>) {
  try {
    const result: boolean = yield call(postUserRegister, action.payload); //payload : user

    yield delay(1000);
    yield put(postUserAsync.success(result));
  } catch (error: any) {
    console.log(error);
    yield put(postUserAsync.failure(error.response.data));
  }
}

function* postUserLoginSaga(
  action: ReturnType<typeof postUserLoginAsync.request>
) {
  try {
    const result: string = yield call(postUserLogin, action.payload); //payload : user
    yield delay(1000);
    yield put(postUserLoginAsync.success(result));
  } catch (error: any) {
    console.log(error.response);
    yield put(postUserLoginAsync.failure(error.response.data));
  }
}

export default function* usersSaga() {
  yield takeEvery(GET_USERS.REQUEST, getUsersSaga);
  yield takeEvery(GET_USER.REQUEST, getUserSaga);
  yield takeEvery(POST_USER.REQUEST, postUserSaga);
  yield takeEvery(POST_USER_LOGIN.REQUEST, postUserLoginSaga);
}
