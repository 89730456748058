import { AsyncState } from "lib";
import { createAsyncAction, ActionType } from "typesafe-actions";
import { LoginRequest, User } from "../types";

export const GET_USERS = {
  REQUEST: "GET_USERS_REQUEST",
  SUCCESS: "GET_USERS_SUCCESS",
  FAILURE: "GET_USERS_FAILURE",
};

export const GET_USER = {
  REQUEST: "GET_USER_REQUEST",
  SUCCESS: "GET_USER_SUCCESS",
  FAILURE: "GET_USER_FAILURE",
};

export const POST_USER = {
  REQUEST: "POST_USER_REQUEST",
  SUCCESS: "POST_USER_SUCCESS",
  FAILURE: "POST_USER_FAILURE",
};

export const POST_USER_LOGIN = {
  REQUEST: "POST_USER_LOGIN_REQUEST",
  SUCCESS: "POST_USER_LOGIN_SUCCESS",
  FAILURE: "POST_USER_LOGIN_FAILURE",
};

export const getUsersAsync = createAsyncAction(
  GET_USERS.REQUEST,
  GET_USERS.SUCCESS,
  GET_USERS.FAILURE
)<any, User[], Error>();

export const getUserAsync = createAsyncAction(
  GET_USER.REQUEST,
  GET_USER.SUCCESS,
  GET_USER.FAILURE
)<number, User, Error>();

export const postUserAsync = createAsyncAction(
  POST_USER.REQUEST,
  POST_USER.SUCCESS,
  POST_USER.FAILURE
)<User, Boolean, Error>();

// response : JWT
export const postUserLoginAsync = createAsyncAction(
  POST_USER_LOGIN.REQUEST,
  POST_USER_LOGIN.SUCCESS,
  POST_USER_LOGIN.FAILURE
)<LoginRequest, string, Error>();

export type UsersAction = ActionType<typeof getUsersAsync>;
export type UserAction = ActionType<typeof getUserAsync>;
export type PostUserAction = ActionType<typeof postUserAsync>;
export type PostUserLoginAction = ActionType<typeof postUserLoginAsync>;

export type UsersState = {
  users: AsyncState<User[], Error>;
};

export type UserState = {
  user: AsyncState<User, Error>;
};

export type PostUserState = {
  registerSuccess: AsyncState<Boolean, Error>;
};

export type PostUserLoginState = {
  token: AsyncState<string, Error>;
};
