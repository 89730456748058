import { FormatDate, departmentToStr } from "lib";
import { Department, Post } from "modules";
import { Link } from "react-router-dom";
import "sass/Tables.scss";

type CommunityItemProps = {
  community: Post;
};

function CommunityItem({ community }: CommunityItemProps) {
  const onClick = () => {};

  return (
    <tr className="post-item-row">
      {/* <td style={{ width: "10%" }}>{departmentToStr(community.department)}</td> */}
      <td style={{ textAlign: "start", paddingLeft: "2%", width: "47%" }}>
        <Link to="/post">
          <span className="post-title" onClick={onClick}>
            {community.title}
          </span>
        </Link>
      </td>
      <td style={{ width: "15%" }}>{community.userId}</td>
      <td style={{ width: "10%" }}>{FormatDate(community.updatedDate)}</td>
      {/* <td style={{ width: "10%" }}>{community.views}</td> */}
    </tr>
  );
}

export default CommunityItem;
