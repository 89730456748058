import { asyncState, createAsyncReducer } from "lib";
import { createReducer } from "typesafe-actions";
import { getVideosAsync, VideosAction, VideosState } from "./videos";

const initialState: VideosState = {
  videos: asyncState.initial(),
};

export const videos = createReducer<VideosState, VideosAction>(
  initialState
).handleAction(
  [getVideosAsync.request, getVideosAsync.success, getVideosAsync.failure],
  createAsyncReducer(getVideosAsync, "videos")
);
