import classnames from "classnames";
import "sass/SlideIndicator.scss";

export type SlideIndicatorProps = {
  selected: boolean;
};

function SlideIndicator({ selected }: SlideIndicatorProps) {
  return (
    <div className={classnames("slide-indicator", { selected: selected })}>
      {/* {selected ? (
        <div className={classnames("slide-indicator", "selected")}></div>
      ) : (
        <div className={classnames("slide-indicator")}></div>
      )} */}
    </div>
  );
}

export default SlideIndicator;
