import classNames from "classnames";
import React, { useState } from "react";
import CommentWrite from "./CommentWrite";

type CommentItemProps = {
  commentId: number;
  profile: string;
  nickname: string;
  content: string;
  date: string;
  replyCommentId: number;
  deleted: boolean;
};

function CommentItem({
  commentId,
  profile,
  nickname,
  content,
  date,
  replyCommentId,
  deleted,
}: CommentItemProps) {
  const isReply = replyCommentId > -1;
  const [doReply, setDoReply] = useState(false);

  const onClickReply = (e: React.MouseEvent) => {
    
    setDoReply(!doReply);
  };

  return (
    <>
      <div className={"comment-container"}>
        <div
          className={
            isReply
              ? classNames("comment-reply", "visible")
              : classNames("comment-reply")
          }
        >
          ㄴ
        </div>
        <div
          className={
            isReply
              ? classNames("comment-body-container", "reply")
              : classNames("comment-body-container")
          }
        >
          <div className="comment-info-profile">
            <img className="comment-profile" src={profile} alt=""></img>
            <span className="comment-nickname">{nickname}</span>
          </div>
          {deleted ? (
            <p className="comment-content deleted">삭제된 글 입니다</p>
          ) : (
            <p className="comment-content">{content}</p>
          )}
          <span className="comment-date">{date}</span>
          {!isReply && !deleted && (
            <span className="comment-reply-button" onClick={onClickReply}>
              답글
            </span>
          )}
        </div>
      </div>
      {doReply && (
        <CommentWrite
          isReply={true}
          onClickCancel={onClickReply}
          onClickSubmit={() => {}}
        ></CommentWrite>
      )}
    </>
  );
}

export default CommentItem;
