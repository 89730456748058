import "sass/Footer.scss";

function Footer() {
  return (
    <footer className="footer">
      <div className="contents">
        <div className="footer-first">대한예수교 장로회 낙원교회</div>
        <ul>
          <li>
            <b>주소 </b> 서울특별시 강북구 삼양로 94 낙원교회 (우) 01214
          </li>
          <li>
            <b>연락처 </b> 02-987-2020
          </li>
          <li>Copyright © Nakwon Church, All rights reserved.</li>
        </ul>
        <div></div>
      </div>
    </footer>
  );
}

export default Footer;
