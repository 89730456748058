import Button from "components/common/Button";
import Input from "components/common/Input";
import { ModalAlert, Select } from "components";
import React, { ChangeEvent, useEffect, useState } from "react";
import "sass/SignUpContainer.scss";
import { defaultUser, department, Department, RootState, User } from "modules";
import { departmentToStr } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { postUserAsync } from "modules/users";
import LoadingProgressView from "components/common/LoadingProgressView";
import { useNavigate } from "react-router-dom";

export function SignUpContainer() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>(defaultUser);
  const isString = (value: string) => isNaN(Number(value)) === true;

  const { data, loading, error } = useSelector(
    (state: RootState) => state.postUser.registerSuccess
  );
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      console.log(`Register Success ${data}`);
    } else if (loading) {
      console.log("Register loading");
    } else if (error) {
      console.log(`Register Fail ${error}`);
      setShowErrorPopup(true);
    }
  }, [data, loading, error]);

  // var passwordValidation = false;
  // var password = "";
  // var passwordConfirm = "";
  const [passwords, setPasswords] = useState({
    password: "",
    passwordConfirm: "",
  });

  // const departments = Object.keys(Department)
  //   .filter(isString)
  //   .map((str) => departmentToStr(Department[str as keyof typeof Department]));
  const departments = [...department];

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, userId: e.target.value });
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, password: e.target.value });
  };

  const onChangePasswordConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, passwordConfirm: e.target.value });
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, name: e.target.value });
  };

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, phoneNumber: e.target.value });
  };

  const onChangeDepartment = (e: ChangeEvent<HTMLSelectElement>) => {
    setUser({ ...user, department: e.target.value });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Submit");

    if (user.userId.length < 4) {
      alert("아이디는 4자 이상이어야 합니다");
      return;
    }

    if (passwords.password !== passwords.passwordConfirm) {
      alert("비밀번호를 확인해주세요");
      return;
    }

    if (passwords.password.length < 6) {
      alert("비밀번호는 6자 이상이어야 합니다");
      return;
    }

    if (user.name.length < 2) {
      alert("이름은 2자 이상이어야 합니다");
      return;
    }

    //TODO: Birthday
    setUser({ ...user, userPassword: passwords.password });
    // JSON.stringify(user);
    // check Password
    dispatch(postUserAsync.request(user));
    return true;
  };

  return (
    <div className="signup-container">
      {data && (
        <ModalAlert
          title="회원가입이 완료되었습니다!"
          body="관리자의 승인 후 글쓰기 등의 활동이 가능합니다"
          onClick={() => {
            navigate("/");
          }}
        ></ModalAlert>
      )}
      {loading && <LoadingProgressView></LoadingProgressView>}
      {showErrorPopup && (
        <ModalAlert
          title="실패"
          body={error!.toString()}
          onClick={() => {
            setShowErrorPopup(false);
          }}
        ></ModalAlert>
      )}
      <form className="signup-form" onSubmit={onSubmit}>
        <div className="signup-title">회원가입</div>
        <div className="signup-input-title margin">*아이디</div>
        <Input
          type="text"
          width="300px"
          height="30px"
          placeholder="4자 이상"
          marginTop="10px"
          maxLength={20}
          onChange={onChangeId}
        ></Input>
        <div className="signup-input-title">*비밀번호</div>
        <Input
          type="password"
          width="300px"
          height="30px"
          placeholder="6자 이상"
          marginTop="10px"
          onChange={onChangePassword}
        ></Input>
        <div className="signup-input-title">*비밀번호 확인</div>
        <Input
          type="password"
          width="300px"
          height="30px"
          placeholder=""
          marginTop="10px"
          onChange={onChangePasswordConfirm}
        ></Input>
        <div className="signup-input-title margin">*이름</div>
        <Input
          type="text"
          width="300px"
          height="30px"
          placeholder="최대 8자"
          marginTop="10px"
          maxLength={8}
          onChange={onChangeName}
        ></Input>
        {/* <div className="signup-input-title">생년월일</div>
        <Input
          type="text"
          width="300px"
          height="30px"
          placeholder=""
          marginTop="10px"
        ></Input> */}
        <div className="signup-input-title">전화번호</div>
        <Input
          type="tel"
          width="300px"
          height="30px"
          placeholder=""
          maxLength={15}
          marginTop="10px"
          onChange={onChangePhoneNumber}
        ></Input>
        <div className="signup-input-title margin">부서</div>
        <Select
          onChange={onChangeDepartment}
          options={departments}
          width={150}
          height={40}
          marginTop={10}
          padding={5}
        ></Select>
        {/* <Input
          type="text"
          width="300px"
          height="30px"
          placeholder="선택하기"
          marginTop="10px"
        ></Input> */}
        <Button
          type="submit"
          width="310px"
          classType="complete"
          text="완료"
          marginTop="30px"
        ></Button>
      </form>
    </div>
  );
}
