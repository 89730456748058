import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "sass/Sections.scss";
import { SubMenuProps } from "../Layout/Header";

export type SectionDirection = "Left" | "Right";

type SectionProps = SubMenuProps & {
  direction: SectionDirection;
  title: String;
  children:
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >[];
  subtitle: String;
};

export function Section({
  direction,
  children,
  title,
  submenus,
  subtitle,
}: SectionProps) {
  const className = classNames("section", "right");
  // direction === "Left"
  //   ? classNames("section", "left")
  //   : classNames("section", "right");
  return (
    <div className={className}>
      <div className="section-title">
        {/* <span className={classNames(submenus.length > 0 ? "title-hidden" : "")}> */}
        <span>{title}</span>
        <div className="section-sub">
          {submenus.length > 0 &&
            submenus.map((sub, i) => (
              <div className="section-sub-item" key={i}>
                {sub.title === subtitle ? (
                  <div className="sub-menu selected">
                    <span>{sub.title}</span>
                  </div>
                ) : (
                  <div className="sub-menu">
                    <Link to={sub.path} reloadDocument>
                      <span>{sub.title}</span>
                    </Link>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="section-body">{children}</div>
    </div>
  );
}

Section.defaultProps = {
  submenus: [],
  children: [],
  dropdownActive: false,
  subtitle: "",
};
