import classNames from "classnames";
import "sass/Pagination.scss";

type PaginationProps = {
  currentPage: number;
  postsPerPage: number;
  totalPosts: number;
  paginate: (number: number) => void;
};

function Pagination({
  currentPage,
  postsPerPage,
  totalPosts,
  paginate,
}: PaginationProps) {
  const paginationsPerPage = 5;
  const pageNumbers: number[] = [];
  const maxPage = Math.ceil(totalPosts / postsPerPage);

  const makePageNumber = () => {
    if (maxPage <= paginationsPerPage) {
      for (let i = 1; i < maxPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startIndex = Math.max(1, currentPage - 2);
      let lastIndex = startIndex + paginationsPerPage;

      if (maxPage < lastIndex) {
        let interval = lastIndex - maxPage - 1;
        startIndex -= interval;
        lastIndex -= interval;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        pageNumbers.push(i);
      }
    }
  };

  makePageNumber();

  return (
    <nav className="page-container">
      <ul className="page-ul">
        {pageNumbers.length === 0 && (
          <li
            className={classNames("page-li", {
              selected: true,
            })}
            key={1}
            onClick={() => {}}
          >
            <span className="page-span">{1}</span>
          </li>
        )}
        {pageNumbers.map((number) => (
          <li
            className={classNames("page-li", {
              selected: number === currentPage,
            })}
            key={number}
            onClick={() => paginate(number)}
          >
            <span className="page-span">{number}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
