import Button from "components/common/Button";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "sass/Write.scss";
import TextImageBody from "./TextImageBody";
import { useDispatch, useSelector } from "react-redux";
import { Post, RootState, defaultPost } from "modules";
import { uploadPostImage } from "api/firebaseManager";
import LoadingProgressView from "components/common/LoadingProgressView";
import { postPostAsync } from "modules/posts";

type WriteTemplateProps = {
  type: string;
};

export type ImageType = {
  src: string;
  key: number;
  file: File;
};

function WriteTemplate({ type }: WriteTemplateProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [post, setPost] = useState<Post>(defaultPost);
  const [uploading, setUploading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [images, setImages] = useState<ImageType[]>([]);
  const [nextImageKey, setNextImageKey] = useState<number>(0);
  const bodyTextDiv = useRef<HTMLDivElement>(null);
  const { data, loading, error } = useSelector(
    (state: RootState) => state.postPost.postPost
  );
  const onTitleEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (bodyTextDiv.current != null) {
        bodyTextDiv.current.focus();
      }
    }
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) =>
    // setTitle(e.target.value);
    setPost({ ...post, title: e.target.value });

  const onSubmint = (e: React.FormEvent) => {
    e.preventDefault();

    // if (title == "" || bodyTextDiv.current?.textContent?.length == 0) {
    //   return;
    // }

    console.log(bodyTextDiv.current?.innerText!);
    setUploading(true);
    setPost({
      ...post,
      userId: 0,
      content: bodyTextDiv.current?.innerText!,
    });
    // upload Images
    if (images.length > 0) {
      uploadPostImage(post.title, images)
        .then((urls) => {
          console.log("UploadComplete : " + urls?.length);
          setPost({ ...post, images: "{" + urls.join(",") + "}" });
          setSubmit(true);
        })
        .catch((error) => {});
    } else {
      setSubmit(true); // useState is async
    }
  };

  const submitData = () => {
    console.log(post);
    dispatch(postPostAsync.request(post));
  };

  useEffect(() => {
    if (submit) {
      setSubmit(false);
      // setLoading(false)
      submitData();
      navigate(-1);
    }

    if (data) {
      // success
      console.log("Write Success");
    } else if (error) {
      console.log(`Write Fail ${error.message}`);
    }
  }, [submit, data, error]);

  const onChangeImageInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return;
    }
    if (e.target.files.length > 0) {
      addImageToTextarea(e.target.files[0]);
    }
    e.target.value = "";
  };

  const addImageToTextarea = (imgFile: File) => {
    let textArea = bodyTextDiv.current;
    if (textArea === null) {
      return;
    }
    setImages(
      images.concat({
        src: URL.createObjectURL(imgFile),
        key: nextImageKey,
        file: imgFile,
      })
    );
    setNextImageKey((prev) => prev + 1);
  };

  const deleteImageFile = (key: number) => {
    setImages(images.filter((image, i) => image.key !== key));
  };

  // <label class="fdd-btn w33p">파일 첨부 <input
  // id="imgFile" name="imgFile"
  // 										accept=".jpg, .png, .bmp, .gif, .pdf" type="file"
  // 										th:onchange="'inputFile(this, \'uploadName\',' +${layoutStat.index}+', '+${imgStat.index} + ', true);'"
  // 										th:field="*{layoutList[__${layoutStat.index}__].imageFileList[__${imgStat.index}__].file}"
  // 										th:id="imgFile+${layoutStat.index}+${imgStat.index}"></label>
  // 		<button class="idd-btn w33p" type="button"
  // 										th:if="${imgStat.index} == 0"
  // 										th:onclick="'addImage(\'' +${layoutStat.index} + '\');'">
  // <span>이미지 추가</span>
  // 										</button>
  return (
    <form
      className="write-form"
      onSubmit={onSubmint}
      encType="multipart/form-data"
    >
      {uploading && <LoadingProgressView></LoadingProgressView>}
      <div className="write-type">{type}</div>
      {/* <div className="write-division"></div> */}
      <input
        className="write-title"
        placeholder="제목을 입력해주세요"
        autoFocus
        onChange={onChangeTitle}
        value={post.title}
        onKeyPress={onTitleEnter}
      ></input>
      <TextImageBody
        images={images}
        bodyTextDiv={bodyTextDiv}
        deleteImageCallback={deleteImageFile}
      ></TextImageBody>
      <div className="button-container">
        <label className="styled-button normal">
          사진추가
          <input
            type="file"
            accept="image/*"
            onChange={onChangeImageInput}
          ></input>
        </label>
        {/* <Button
          classType="normal"
          type="button"
          width="100px"
          height="40px"
          text="사진추가"
        ></Button> */}
        <Button
          classType="complete"
          type="submit"
          width="100px"
          height="40px"
          text="완료"
        ></Button>
        <Button
          classType="cancel"
          type="submit"
          width="100px"
          height="40px"
          text="취소"
        ></Button>
      </div>
    </form>
  );
}

export default WriteTemplate;
