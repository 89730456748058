import { AsyncState } from "lib";
import { YoutubeVideo } from "modules";
import { ActionType, createAsyncAction } from "typesafe-actions";

export const GET_VIDEOS = {
  REQUEST: "GET_VIDEOS_REQUEST",
  SUCCESS: "GET_VIDEOS_SUCCESS",
  FAILURE: "GET_VIDEOS_FAILURE",
};

export const getVideosAsync = createAsyncAction(
  GET_VIDEOS.REQUEST,
  GET_VIDEOS.SUCCESS,
  GET_VIDEOS.FAILURE
)<number, YoutubeVideo[], Error>();

export type VideosAction = ActionType<typeof getVideosAsync>;
export type VideosState = {
  videos: AsyncState<YoutubeVideo[], Error>;
};
