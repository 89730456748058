import React from "react";
import { communitySubmenus } from "../Layout/Header";
import { Section, SectionDirection } from "../common/SectionComponents";

type CommunityProps = {
  sectionDirection: SectionDirection;
  title: string;
  children: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
};

function Community({ sectionDirection, title, children }: CommunityProps) {
  return (
    <Section
      direction={sectionDirection}
      title={title}
      submenus={communitySubmenus}
    >
      {children}
    </Section>
  );
}

export default Community;
