import { ModalAlert } from "components";
import Button from "components/common/Button";
import Input from "components/common/Input";
import LoadingProgressView from "components/common/LoadingProgressView";
import { LoginRequest, RootState } from "modules";
import { postUserLoginAsync } from "modules/users";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "sass/LoginContainer.scss";

export function LoginController() {
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    userId: "",
    userPassword: "",
  });
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.login.token
  );
  const dispatch = useDispatch();

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginRequest({ ...loginRequest, userId: e.target.value });
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginRequest({ ...loginRequest, userPassword: e.target.value });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(postUserLoginAsync.request(loginRequest));
    // console.log(userId + ":" + userPassword + ":" + data);
  };

  useEffect(() => {
    if (loading) {
      console.log("loading" + loading);
    } else if (data) {
      console.log("refreshToken" + data);
      navigate(-1);
    } else if (error) {
      console.log("Error" + error);
      setShowPopup(true);
    }
  }, [data, error, loading, navigate]);

  return (
    <div className="login-container">
      {loading && <LoadingProgressView></LoadingProgressView>}
      {showPopup && (
        <ModalAlert
          title="로그인 실패"
          body="아이디 혹은 비밀번호를 확인해주세요."
          onClick={() => {
            setShowPopup(false);
          }}
        ></ModalAlert>
      )}
      <form className="login-form" onSubmit={onSubmit}>
        <div className="login-title">로그인</div>
        <Input
          type="text"
          width="300px"
          height="30px"
          placeholder="아이디를 입력해주세요"
          marginTop="20px"
          onChange={onChangeId}
        ></Input>
        <Input
          type="password"
          width="300px"
          height="30px"
          placeholder="비밀번호를 입력해주세요"
          marginTop="10px"
          onChange={onChangePassword}
        ></Input>
        <Button
          classType="complete"
          type="submit"
          text="로그인"
          width="315px"
          marginTop="30px"
        ></Button>
        <Link style={{ marginTop: "30px" }} to="/signup" reloadDocument>
          <p>회원가입</p>
        </Link>
      </form>
      {data && <button>gd</button>}
    </div>
  );
}
