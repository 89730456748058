import React, { HTMLInputTypeAttribute } from "react";
import "sass/Input.scss";

type InputProps = {
  type: HTMLInputTypeAttribute;
  width: string;
  height: string;
  placeholder: string;
  marginTop: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  maxLength: number;
};

function Input({
  type,
  width,
  height,
  placeholder,
  marginTop,
  onChange,
  maxLength,
}: InputProps) {
  //TODO: required input fail => border red
  return (
    <>
      {
        <input
          title=""
          className="styled-input"
          type={type}
          style={{ width: width, height, marginTop: marginTop }}
          placeholder={placeholder}
          onChange={onChange}
          // value={}
          {...(maxLength > 0 && { maxLength: maxLength })}
        ></input>
      }
    </>
  );
}

Input.defaultProps = {
  marginTop: "0",
  maxLength: 0,
  onChange: null,
};

export default Input;
