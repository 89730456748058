import { FormatDate } from "lib";
import { Post } from "modules";
import { Link } from "react-router-dom";
import "sass/Tables.scss";

type NoticeItemProps = {
  notice: Post;
};
function NoticeItem({ notice }: NoticeItemProps) {
  const onClick = () => {
    console.log("Click");
    console.log(new Date());
  };
  return (
    <tr className="post-item-row">
      {/* <td style={{ width: "8%" }}>{notice.id}</td> */}
      <td style={{ textAlign: "start", paddingLeft: "3%", width: "50%" }}>
        <Link to={`/notice/${notice.id}`}>
          <span className="post-title" onClick={onClick}>
            {notice.title}
          </span>
        </Link>
      </td>
      <td style={{ width: "20%" }}>
        {/* TODO : userId 조회 후 닉네임으로 */}
        {notice.userId === 0 ? "관리자" : notice.userId}
      </td>
      {/* <td style={{ width: "10%" }}>{DateToString(notice.updatedDate)}</td> */}
      <td style={{ width: "10%" }}>{FormatDate(notice.updatedDate)}</td>
      {console.log(notice)}
      {/* <td style={{ width: "10%" }}>{notice.views}</td> */}
    </tr>
  );
}

export default NoticeItem;
