import MetaTag from "SEOMetaTag";
import { CommunityTable, Community, Pagination, WriteButton } from "components";
import { postTypeToStr } from "lib";
import { RootState, Post, PostType } from "modules";
import { getPostsAsync } from "modules/posts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "sass/Boards.scss";

type CommunityProps = {
  community: PostType;
};

export function CommunityContainer({ community }: CommunityProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;
  const { data, loading, error } = useSelector(
    (state: RootState) => state.posts.posts
  );
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  useEffect(() => {
    setCurrentPage(1);
  }, [community]);

  function currentPosts(communityPosts: Post[]) {
    let currentPosts = [];
    currentPosts = communityPosts.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // let dummyPostsLength = 12;
    // let dummys: Post[] = [];
    // for (let index = 0; index < dummyPostsLength; index++) {
    //   dummys.push(dummyPost(index, "COMMUNITY", Department.Elementary));
    // }
    // dispatch(getCommunityPostsAsync.success(dummys));
    dispatch(getPostsAsync.request(community));
  }, [dispatch, community]);

  return (
    <>
      <MetaTag
        title="낙원교회"
        description="서울시 강북구 미아동에 위치한 대한예수교 장로회 합동측 낙원교회입니다."
        keywords="강북구, 미아동, 삼양동, 낙원교회"
        url=""
      ></MetaTag>
      <Community sectionDirection="Right" title={postTypeToStr(community)}>
        <div className="section-body-container">
          {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
          {error && <p style={{ textAlign: "center" }}>에러 발생!</p>}
          {data && (
            <>
              <CommunityTable
                communityPosts={currentPosts(data)}
              ></CommunityTable>
              <WriteButton to="/write"></WriteButton>
            </>
          )}
          <Pagination
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            totalPosts={data !== null ? data!.length : 0}
            paginate={setCurrentPage}
          ></Pagination>
        </div>
      </Community>
    </>
  );
}
