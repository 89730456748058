import { Link } from "react-router-dom";
import "sass/MenuButton.scss";

type MenuButtonProps = {
  title: string;
  img: string;
  url: string;
  colorHex: string;
};

function MenuButton({ title, url, img, colorHex }: MenuButtonProps) {
  return (
    <div
      className="menu-button-container"
      // style={{ border: "solid 2px" + colorHex, borderRadius: 12 }}
    >
      <Link to={url}>
        <img
          className="menu-button-img"
          alt=""
          src={img}
          // style={{ filter: `opacity(0.8) drop-shadow(0 0 0 ${colorHex})` }}
        />
        <p className="menu-button-title">{title}</p>
      </Link>
    </div>
  );
}

export default MenuButton;
