import axios from "axios";
import { Youtube, YoutubeVideo } from "modules";

// const YOUTUBE_URL = "https://www.googleapis.com/youtube/v3/search";
const YOUTUBE_URL = "https://www.googleapis.com/youtube/v3/playlistItems";
// const channelId = "UCrKlCHQqQS_PUtHs82abqLA";
const playListId = "UUrKlCHQqQS_PUtHs82abqLA";
const key = "AIzaSyB4SkGCK9OHkulWJORoCuqozD1uKnGHbI4";

export const axiosYoutube = axios.create({
  baseURL: YOUTUBE_URL,
});

/* response structure
{  
  "kind": "youtube#searchListResponse",
  "etag": etag,
  "nextPageToken": string,
  "prevPageToken": string,
  "pageInfo": {
    "totalResults": integer,
    "resultsPerPage": integer
  },
  "items": [
    search Resource
  ]
}*/

export async function getYoutubeVideos(page: number) {
  const response = await axiosYoutube.get<Youtube>("", {
    params: {
      part: "snippet",
      playlistId: playListId,
      key: key,
      maxResults: 9,
      pageToken: "",
    },
  });

  const json: Youtube = response.data;
  // const nextPageToken = Object.entries.ss;
  const items = json.items;

  const youtubes: YoutubeVideo[] = [];
  for (let index = 0; index < items.length; index++) {
    const snippet = items[index].snippet;
    const thumbnail: string = snippet.thumbnails.medium.url;
    const url: string = items[index].id.videoId;
    youtubes.push({
      id: index,
      thumbnailUrl: thumbnail,
      url: url,
      title: snippet.title,
    });
  }
  return youtubes;
}

export async function getVideos() {
  // TODO: ignore baseURL
  // const response = await axios.get<Video[]>(YOUTUBE_URL);
  // console.log("RESPONSE" + response.data);
  // return response.data;
}
