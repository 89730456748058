import { Section, WriteTemplate } from "components";
import Button from "components/common/Button";
import Input from "components/common/Input";
import React, { useState } from "react";

function WritePostContainer() {
  const [authorized, setAuthorized] = useState(false);
  const [password, setPassword] = useState("");
  const onClickPassword = () => {
    if (password === "0316") {
      setAuthorized(true);
    }
  };
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <Section direction="Right" title="글쓰기">
      <>
        {!authorized && (
          <div>
            <div style={{ marginTop: "50px" }}>관리자 번호 입력</div>
            <Input
              type="text"
              width="300px"
              height="30px"
              placeholder=""
              marginTop="10px"
              maxLength={20}
              onChange={onChangePassword}
            ></Input>
            <Button
              classType="complete"
              type="button"
              text="확인"
              onClick={onClickPassword}
            />
          </div>
        )}
        {authorized && <WriteTemplate type="교회소식" />}
      </>
    </Section>
  );
}

export default WritePostContainer;
