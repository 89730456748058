import Button from "components/common/Button";
import { Section } from "components/common/SectionComponents";
import { FormatDate, postTypeToStr } from "lib";
import { Comment, Post } from "modules";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommentItem from "./CommentItem";

type PostProps = {
  post: Post;
};

function PostLayout({ post }: PostProps) {
  const navigate = useNavigate();
  // const postTypeToString = post.type === "COMMUNITY" ? "커뮤니티" : "교회소식";
  let [sortedComments, setSortedComments] = useState<Comment[]>([]);

  useEffect(() => {
    const sortingComments = () => {
      // const comments = post.comments;
      const comments: Comment[] = [];
      let tempComments: Comment[] = comments.slice();

      for (let i = 0; i < comments.length; i++) {
        if (comments[i].replyCommentId > -1) {
          for (let j = 0; j < tempComments.length; j++) {
            if (tempComments[j].id === comments[i].replyCommentId) {
              tempComments.splice(tempComments.indexOf(comments[i]), 1); //delete item
              let insertIndex;
              //find insert index
              for (let k = j; k < tempComments.length; k++) {
                insertIndex = k;
                if (
                  tempComments[k].replyCommentId !== comments[i].replyCommentId
                ) {
                  break;
                } else {
                  if (tempComments[k].id > comments[i].id) {
                    // if sameReplyComment, compare id
                    continue;
                  } else {
                    break;
                  }
                }
              }

              //insert at index
              tempComments = [
                ...tempComments.slice(0, insertIndex),
                comments[i],
                ...tempComments.slice(insertIndex),
              ];
            }
          }
        }
      }

      setSortedComments(tempComments);
    };

    sortingComments();
  }, []);

  return (
    <Section direction="Right" title={postTypeToStr(post.postType)}>
      <div className="section-body-container">
        <div className="post-container">
          <div className="post-division"></div>
          <div className="post-title">{post.title}</div>
          <div className="post-info">
            <div className="post-profile-container">
              {/* <img className="post-profile" src="" alt=""></img> */}
              <div className="post-nickname">
                {post.userId === 0 ? "관리자" : post.userId}
              </div>
            </div>
            <div className="post-date">{FormatDate(post.updatedDate)}</div>
          </div>
          <div className="divider-horizontal"></div>
          <p className="post-body">{post.content}</p>
          <div className="button-container">
            <Button
              classType="normal"
              type="button"
              width="100px"
              height="40px"
              text="목록"
              onClick={() => {
                navigate(-1);
              }}
            ></Button>
          </div>
          <div className="divider-horizontal"></div>
          {sortedComments.map((comment, i) => (
            <div key={comment.id}>
              <CommentItem
                commentId={comment.id}
                profile={comment.profileImg}
                nickname={comment.nickName}
                content={comment.content}
                date={FormatDate(comment.updatedDate)}
                replyCommentId={comment.replyCommentId}
                deleted={comment.deleted}
              ></CommentItem>
              {i !== sortedComments.length - 1 && (
                <div className="divider-horizontal comment"></div>
              )}
            </div>
          ))}
        </div>
        <div
          className="post-container"
          style={{ marginTop: "0px", padding: "5px", border: "none" }}
        >
          {/* <CommentWrite isReply={false} onClickSubmit={() => {}}></CommentWrite> */}
        </div>
      </div>
    </Section>
  );
}

export default PostLayout;
