import { API_SERVER_URL } from "api";
import axios from "axios";
import { Bulletin } from "modules";

const BULLETINS_URL = API_SERVER_URL + "bulletins/";

export async function getBulletins() {
  const response = await axios.get<Bulletin[]>(BULLETINS_URL);
  console.log("RESPONSE" + response.data);

  return response.data;
}
