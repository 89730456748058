import classNames from "classnames";
import React from "react";
import "sass/Button.scss";

type ButtonType = "submit" | "reset" | "button" | undefined;

type ButtonProps = {
  type: ButtonType;
  color: string;
  width: string;
  height: string;
  text: string;
  marginTop: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  classType: "complete" | "cancel" | "normal";
};

function Button({
  type,
  color,
  width,
  height,
  text,
  classType,
  marginTop,
  onClick,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames("styled-button", classType)}
      style={{
        width: width,
        height: height,
        color: color,
        marginLeft: "0px",
        marginTop: marginTop,
        padding: "0px",
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  color: "",
  width: "100px",
  height: "40px",
  text: "",
  marginTop: "0",
  onClick: () => {},
};

export default Button;
