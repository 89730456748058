import React, { useCallback, useEffect, useRef, useState } from "react";
import "sass/Write.scss";
import { ImageType } from "./WriteTemplate";

type TextImageBodyProps = {
  bodyTextDiv: React.RefObject<HTMLDivElement>;
  images: ImageType[];
  deleteImageCallback: (key: number) => void;
};

function TextImageBody({
  bodyTextDiv,
  images,
  deleteImageCallback,
}: TextImageBodyProps) {
  const [cursorLine, setCursorLine] = useState(0);
  const [currentImageCount, setCurrentImageCount] = useState(0);
  const focusedRef = useRef<HTMLDivElement>();
  const container = useRef<HTMLDivElement>(null);

  const onSelectDiv = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const sel = document.getSelection();
    if (sel === null) {
      return;
    }
    let selectedDiv: Node;
    let parentDiv: HTMLElement;
    // NodeType : 1 = div,   3 = Text
    if (sel.anchorNode?.nodeType === 1) {
      // Empty Text Line
      selectedDiv = sel.anchorNode;
      parentDiv = selectedDiv.parentElement!;
    } else if (sel.anchorNode?.nodeType === 3) {
      selectedDiv = sel.anchorNode?.parentElement!;
      parentDiv = selectedDiv?.parentElement!;
    } else {
      return;
    }

    if ((selectedDiv as HTMLElement).id === "write-body-container") {
      // when body is empty selectedDiv is write-body-container
      setCursorLine(Math.max(selectedDiv.childNodes.length - 1, 0));
    } else {
      setCursorLine(
        Array.prototype.indexOf.call(parentDiv?.childNodes, selectedDiv)
      );
    }
  }, []);

  const onDeleteImage = (e: React.KeyboardEvent) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      let selectedDiv: HTMLDivElement = focusedRef.current as HTMLDivElement;
      let fromFocus = true;

      if (selectedDiv === undefined) {
        // for deleting with none click (only using backspace key)
        selectedDiv = document.getSelection()?.anchorNode as HTMLDivElement;
        fromFocus = false;
      }

      if (selectedDiv.className === "write-img-container") {
        e.preventDefault();
        const key: number = Number.parseInt(
          selectedDiv.getAttribute("data-key")!
        );
        selectedDiv.remove();
        focusedRef.current = undefined;
        setCurrentImageCount((prev) => Math.max(prev - 1, 0));
        deleteImageCallback(key);

        // prevent cursor move to Last
        if (fromFocus) {
          placeCaretAtEnd(bodyTextDiv.current!);
        }
      }
    }
  };

  const placeCaretAtEnd = (el: HTMLDivElement) => {
    el.focus();
    if (
      typeof window.getSelection != "undefined" &&
      typeof document.createRange != "undefined"
    ) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel!.removeAllRanges();
      sel!.addRange(range);
    }
  };

  useEffect(() => {
    const onFocusImageDiv = (e: FocusEvent) => {
      const target = e.target as HTMLDivElement;
      if (target !== undefined) {
        focusedRef.current = target;
      }
    };

    const onBlurImageDiv = () => {
      focusedRef.current = undefined;
    };

    // Add Image
    if (images.length > currentImageCount) {
      const imageDiv = document.createElement("div");
      const key = images[images.length - 1].key.toString();
      imageDiv.onfocus = onFocusImageDiv;
      imageDiv.onblur = onBlurImageDiv;
      imageDiv.setAttribute("class", "write-img-container");
      imageDiv.setAttribute("key", key);
      imageDiv.setAttribute("data-key", key);
      imageDiv.tabIndex = 0;
      const image = document.createElement("img");
      image.className = "write-img";
      image.draggable = false;
      image.src = images[images.length - 1].src;
      image.addEventListener("load", () => {
        if (bodyTextDiv.current !== null) {
          bodyTextDiv.current.scrollTop = image.offsetTop;
        }
      });
      imageDiv.append(image);

      bodyTextDiv.current!.insertBefore(
        imageDiv,
        bodyTextDiv.current!.children[cursorLine]
      );

      let nextDiv: HTMLElement = bodyTextDiv.current!.children[
        cursorLine + 1
      ] as HTMLElement;
      if (nextDiv === undefined) {
        const newDiv = document.createElement("div");
        newDiv.setAttribute("style", "display:list-item");
        newDiv.setAttribute("class", "write-body-text");
        bodyTextDiv.current!.append(newDiv);
        nextDiv = newDiv;
      }

      placeCaretAtEnd(bodyTextDiv.current!);

      setCurrentImageCount(images.length);
    }
  }, [images]);

  /* <div key={i + 100} contentEditable></div> */

  return (
    <article className="write-body" ref={container}>
      <div
        contentEditable
        ref={bodyTextDiv}
        onKeyDown={onDeleteImage}
        placeholder="내용을 입력해주세요"
        id="write-body-container"
        style={{ overflowY: "auto" }}
        onSelect={onSelectDiv}
      ></div>
    </article>
  );
}

export default TextImageBody;
