import classNames from "classnames";
import React from "react";
import "sass/ModalAlert.scss";

type ModalYoutubeProps = {
  url: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

function ModalYoutube({ url, onClick }: ModalYoutubeProps) {
  const youtubePrefix = "https://youtube.com/embed/";
  return (
    <div className="modal-alert-bg" onClick={onClick}>
      <div
        className={classNames("modal-alert-container", "modal-video-container")}
      >
        <iframe
          className="modal-video"
          title="youtube"
          id="player"
          src={youtubePrefix + url}
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default ModalYoutube;
