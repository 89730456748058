import { getVideos, getYoutubeVideos } from "api/videos";
import { YoutubeVideo } from "modules";
import { call, put, takeEvery } from "redux-saga/effects";
import { getVideosAsync, GET_VIDEOS } from "./videos";

function* getVideosSaga(action: ReturnType<typeof getVideosAsync.request>) {
  try {
    const videos: YoutubeVideo[] = yield call(getYoutubeVideos, action.payload);
    yield put(getVideosAsync.success(videos));
  } catch (error: any) {
    yield put(getVideosAsync.failure(error));
  }
}

export default function* videosSaga() {
  yield takeEvery(GET_VIDEOS.REQUEST, getVideosSaga);
}
