import { Post } from "modules";
import CommunityItem from "./CommunityItem";
import "sass/Tables.scss";

type CommunityTableProps = {
  communityPosts: Post[];
};

function CommunityTable({ communityPosts: communities }: CommunityTableProps) {
  return (
    <table className="community-table">
      <thead>
        <tr className="post-header">
          {/* <th style={{ width: "8%" }}></th> */}
          {/* <th style={{ width: "10%" }}></th> */}
          <th style={{ width: "65%" }}>제목</th>
          <th style={{ width: "15%" }}>글쓴이</th>
          <th style={{ width: "10%" }}>등록일</th>
          <th style={{ width: "10%" }}>조회</th>
        </tr>
      </thead>
      <tbody className="community-body">
        {communities.map((post) => (
          <CommunityItem key={post.id} community={post}></CommunityItem>
        ))}
      </tbody>
    </table>
  );
}

export default CommunityTable;
