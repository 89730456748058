import { asyncState, createAsyncReducer } from "lib";
import { createReducer } from "typesafe-actions";
import {
  getPostAsync,
  getPostsAsync,
  PostAction,
  PostPostAction,
  postPostAsync,
  PostPostState,
  PostsAction,
  PostsState,
  PostState,
} from "./posts";

const initialState: PostsState = {
  posts: asyncState.initial(),
};

// export const notices = createAsyncReducer(getNoticesAsync, "notices");
export const posts = createReducer<PostsState, PostsAction>(
  initialState
).handleAction(
  [getPostsAsync.request, getPostsAsync.success, getPostsAsync.failure],
  createAsyncReducer(getPostsAsync, "posts")
);
// export const posts = createReducer<PostsState, PostsAction>(initialState)
//   .handleAction(getPostsAsync.request, (state) => {
//     return { ...state, posts: asyncState.load() };
//   })
//   .handleAction(getPostsAsync.success, (state, action) => {
//     return {
//       ...state,
//       posts: asyncState.success(action.payload),
//     };
//   })
//   .handleAction(getPostsAsync.failure, (state, action) => {
//     return {
//       ...state,
//       notices: asyncState.error(action.payload),
//     };
//   });

export const post = createReducer<PostState, PostAction>({
  post: asyncState.initial(),
} as PostState).handleAction(
  [getPostAsync.request, getPostAsync.success, getPostAsync.failure],
  createAsyncReducer(getPostAsync, "post")
);

export const postPost = createReducer<PostPostState, PostPostAction>({
  postPost: asyncState.initial(),
} as PostPostState).handleAction(
  [postPostAsync.request, postPostAsync.success, postPostAsync.failure],
  createAsyncReducer(postPostAsync, "postPost")
);
