import { AsyncState } from "lib";
import { Post } from "modules";
import { createAsyncAction, ActionType } from "typesafe-actions";

export const GET_POSTS = {
  REQUEST: "GET_POSTS_REQUEST",
  SUCCESS: "GET_POSTS_SUCCESS",
  FAILURE: "GET_POSTS_FAILURE",
};

export const GET_POST = {
  REQUEST: "GET_POST_REQUEST",
  SUCCESS: "GET_POST_SUCCESS",
  FAILURE: "GET_POST_FAILURE",
};

export const POST_POST = {
  REQUEST: "POST_POST_REQUEST",
  SUCCESS: "POST_POST_SUCCESS",
  FAILURE: "POST_POST_FAILURE",
};

// Action Create
// export const getPosts = createAction(GET_POSTS.REQUEST)<PostType>();
// export const getPost = (postId: number) => {};

export const getPostsAsync = createAsyncAction(
  GET_POSTS.REQUEST,
  GET_POSTS.SUCCESS,
  GET_POSTS.FAILURE
)<string, Post[], Error>();

export const getPostAsync = createAsyncAction(
  GET_POST.REQUEST,
  GET_POST.SUCCESS,
  GET_POST.FAILURE
)<{ type: string; id: number }, Post, Error>();

export const postPostAsync = createAsyncAction(
  POST_POST.REQUEST,
  POST_POST.SUCCESS,
  POST_POST.FAILURE
)<Post, boolean, Error>();

export type PostsAction = ActionType<typeof getPostsAsync>;
export type PostAction = ActionType<typeof getPostAsync>;
export type PostPostAction = ActionType<typeof postPostAsync>;

export type PostsState = {
  posts: AsyncState<Post[], Error>;
};

export type PostState = {
  post: AsyncState<Post, Error>;
};

export type PostPostState = {
  postPost: AsyncState<boolean, Error>;
};
