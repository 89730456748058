import { getBulletins } from "api/bulletins";
import { Bulletin } from "modules/types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getBulletinsAsync, GET_BULLETINS } from "./bulletins";

function* getBulletinsSaga(
  action: ReturnType<typeof getBulletinsAsync.request>
) {
  try {
    const bulletins: Bulletin[] = yield call(getBulletins);
    yield put(getBulletinsAsync.success(bulletins));
  } catch (error: any) {
    yield put(getBulletinsAsync.failure(error));
  }
}

export default function* bulletinsSaga() {
  yield takeEvery(GET_BULLETINS.REQUEST, getBulletinsSaga);
}
