import React, { useEffect, useState, useCallback, CSSProperties } from "react";
import SlideIndicator, { SlideIndicatorProps } from "./SlideIndicator";

type SlideIndicatorsProps = {
  count: number;
  selectedIndex: number;
};

function SlideIndicators({ count, selectedIndex }: SlideIndicatorsProps) {
  const [dots, setDots] = useState<SlideIndicatorProps[]>([]);

  const selectCurrentIndex = useCallback(() => {
    setDots((prev) =>
      prev.map(
        (props, index) => (props = { selected: selectedIndex === index })
      )
    );
  }, [selectedIndex]);

  useEffect(() => {
    const makeDots = () => {
      let temp: SlideIndicatorProps[] = [];
      for (let index = 0; index < count; index++) {
        temp.push({ selected: index === selectedIndex });
      }
      setDots((state) => [...state, ...temp]);
    };

    makeDots();
    // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    selectCurrentIndex();
  }, [selectCurrentIndex]);

  const containerStyle: CSSProperties = {
    display: "flex",
    alignSelf: "center",
    bottom: "5%",
    margin: "auto",
  };

  return (
    <div style={containerStyle}>
      {dots.map((dot, i) => (
        <SlideIndicator selected={dot.selected} key={i}></SlideIndicator>
      ))}
    </div>
  );
}

export default SlideIndicators;
