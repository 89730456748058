import { Link } from "react-router-dom";
import "sass/Boards.scss";

function WriteButton(props: { to: string }) {
  return (
    <Link className="write-button" to={props.to}>
      <span style={{ marginBottom: "15%" }}>+</span>
    </Link>
  );
}

export default WriteButton;
