import ModalYoutube from "components/common/ModalYoutube";
import { YoutubeVideo } from "modules";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "sass/Grid.scss";

type VideoGridProps = {
  videos: YoutubeVideo[];
};

function VideoGrid({ videos }: VideoGridProps) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const onClick = (url: string, e: React.MouseEvent) => {
    e.preventDefault();
    console.log("onclick : url $0", url);
    setYoutubeUrl(url);
    setShowModal(true);
  };

  return (
    <div className="grid-container">
      {videos.map((video) => (
        <div
          key={video.id}
          className="grid-item"
          onClick={(e) => {
            onClick(video.url, e);
          }}
        >
          <img className="img-thumbnail" src={video.thumbnailUrl} alt=""></img>
          <div className="div-info-container">
            {/* <div className="div-subtitle">2022.06.30</div> */}
            <div className="div-title">{video.title}</div>
          </div>
        </div>
      ))}
      {showModal && (
        <ModalYoutube
          url={youtubeUrl}
          onClick={() => {
            setShowModal(false);
          }}
        ></ModalYoutube>
      )}
    </div>
  );
}

export default VideoGrid;
