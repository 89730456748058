import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { bulletins } from "./bulletins";
import bulletinsSaga from "./bulletins/sagas";
import { post, postPost, posts } from "./posts";
import postsSaga from "./posts/sagas";
import { postUser, postUserLogin, user, users } from "./users";
import usersSaga from "./users/sagas";
import { videos } from "./videos";
import videosSaga from "./videos/sagas";
export * from "./types";

const rootReducer = combineReducers({
  posts: posts,
  post: post,
  postPost: postPost,
  users: users,
  user: user,
  postUser: postUser,
  login: postUserLogin,
  videos: videos,
  bulletins: bulletins,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([postsSaga(), usersSaga(), videosSaga(), bulletinsSaga()]);
}
