import axios from "axios";
import { LoginRequest, User } from "modules";
import { axiosPrivate } from "./apiManager";

const USERS_URL = "users/";

export async function getUsers() {
  const response = await axios.get<User[]>(USERS_URL);
  console.log("RESPONSE" + response.data);

  return response.data;
}

export async function getUserById(id: number) {
  const response = await axios.get<User>(USERS_URL + id);
  console.log("RESPONSE" + response.data);

  return response.data;
}

// params
// user : only including userId & userPassword
export async function postUserLogin(user: LoginRequest) {
  const response = await axios.post(USERS_URL + "login", JSON.stringify(user));
  console.log(response);
  console.log(response.headers);
  console.log(response.status);
  localStorage.setItem("accessToken", response.data);

  return response.data;
}

export async function postUserRegister(user: User) {
  // axiosPrivate.interceptors.request.use((req) =>
  //   // console.log("Starting Request", JSON.stringify(req, null, 2))
  //   {
  //     console.log(req);
  //     // return req must be called!!!!!!!!!!! when using interceptors
  //     return req;
  //   }
  // );
  // console.log(JSON.stringify(user));
  return await axiosPrivate
    .post<Boolean>(USERS_URL, JSON.stringify(user))
    .then((response) => {
      console.log(response);
      console.log(response.statusText);
      return response.status === 200;
    })
    .catch((error) => {
      if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log(error.response.data); //custom message
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        console.log("Error", error.message);
      }
      console.log(error.config);

      throw error;
    });
}

export async function putUser(id: number, user: User) {
  const response = await axios.put<User>(USERS_URL + id, user);
  console.log("RESPONSE" + response.data);

  return response.data;
}

export async function deleteUser(id: number) {
  const response = await axios.delete<User>(USERS_URL + id);
  console.log("RESPONSE" + response.data);

  return response.data;
}
