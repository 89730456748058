// Date String type
// TimeString : 23:15:30 GMT+0200 (CEST)
// LocaleTimeString('it-IT')) : 03:15:30
// LocaleTimeString('en-US')) : 03:15:30 AM

import { Department, PostType } from "modules";

// yyyy-MM-dd-HH-mm-ss -> yyyy.MM.dd
export function FormatDate(date: string): string {
  if (date === undefined) {
    return "";
  }
  // return date.toISOString().substring(0, 10).replaceAll("-", ".");
  const a = date.split("-");
  return a[0] + "." + a[1] + "." + a[2];
}

export const postTypeToStr = (community: PostType) => {
  switch (community) {
    case "notice":
      return "교회소식";
    case "senior":
      return "장년";
    case "school":
      return "주일학교";
    case "construct":
      return "재건축";
  }
};

export const departmentToStr = (department: Department) => {
  let str = "";
  switch (department) {
    case "none":
      str = "";
      break;
    case "senior":
      str = "장년부";
      break;
    case "youth":
      str = "청년부";
      break;
    case "middleHigh":
      str = "중고등부";
      break;
    case "elementary":
      str = "유초등부";
      break;
    case "kindergarten":
      str = "유치부";
      break;
  }
  return str;
};
