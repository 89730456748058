import React, { useEffect, useRef, useState } from "react";
import "sass/MainBanner.scss";
import SlideIndicators from "./SlideIndicators";

function MainBanner() {
  const scrollView = useRef<HTMLUListElement>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const pageCount = 1;
  const alt = "";

  useEffect(() => {
    let pressed: boolean = false;
    let moveSize: number;
    let pageWidth: number;

    const { current } = scrollView;

    current?.addEventListener("mousedown", mouseDownHandler);
    current?.addEventListener("mousemove", mouseMoveHandler);
    current?.addEventListener("mouseup", paginationEvent);
    current?.addEventListener("mouseleave", paginationEvent);

    function mouseDownHandler(e: MouseEvent) {
      pageWidth = current!.offsetWidth;

      pressed = true;
      moveSize = 0;
    }

    function mouseMoveHandler(e: MouseEvent) {
      e.preventDefault();
      if (!pressed) {
        return;
      }
      moveSize += e.movementX;

      if (current != null) {
        current.scrollLeft -= e.movementX;
      }
    }

    function paginationEvent(e: MouseEvent) {
      if (!pressed) {
        return;
      }
      pressed = false;
      if (current != null) {
        let page = currentPage;

        let minDis = pageWidth / 10;
        if (Math.abs(moveSize) > minDis) {
          if (moveSize < 0) {
            page++;
          } else {
            page--;
          }
        }

        if (page >= pageCount) {
          page = pageCount - 1;
        } else if (page < 0) {
          page = 0;
        }

        setCurrentPage(page);
        current.scrollTo({
          top: 0,
          left: page * pageWidth,
          behavior: "smooth",
        });

        moveSize = 0;
      }
    }

    return () => {
      current?.removeEventListener("mousedown", mouseDownHandler);
      current?.removeEventListener("mousemove", mouseMoveHandler);
      current?.removeEventListener("mouseup", (e) => paginationEvent(e));
      current?.removeEventListener("mouseleave", (e) => paginationEvent(e));
    };
  }, [currentPage]);

  return (
    <div className="banner-container">
      <ul className="scroll-view" ref={scrollView}>
        <li>
          <div className="banner-first">
            <img src={"/img/home/img_slogan_2024.png"} alt={alt}></img>
          </div>
        </li>
        {/* <li>
            배너2
            <img src={""} alt={alt}></img>
          </li>
          <li>
            배너3
            <img src={""} alt={alt}></img>
          </li> */}
      </ul>
      <SlideIndicators
        count={pageCount}
        selectedIndex={currentPage}
      ></SlideIndicators>
    </div>
  );
}

export default MainBanner;
