import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NoticeTable, Section, Pagination, WriteButton } from "components";
import { RootState, Post } from "modules";
import "sass/Boards.scss";
import { getPostsAsync } from "modules/posts";
import MetaTag from "SEOMetaTag";

export function NoticeListContainer() {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.posts.posts
  );

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  function currentPosts(notices: Post[]) {
    let currentPosts = [];
    currentPosts = notices.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  }

  useEffect(() => {
    // let dummyPostsLength = 12;
    // let dummys: Post[] = [];
    // for (let index = 0; index < dummyPostsLength; index++) {
    //   dummys.push(dummyPost(index, "NOTICE"));
    // }
    dispatch(getPostsAsync.request("notice"));
    // dispatch(getPostsAsync.request("PostTyp"));
  }, [dispatch]);

  return (
    <>
      <MetaTag
        title="낙원교회 교회소식"
        description="서울시 강북구 미아동에 위치한 대한예수교 장로회 합동측 낙원교회입니다."
        keywords="강북구, 미아동, 삼양동, 낙원교회"
        url=""
      ></MetaTag>
      <Section direction="Right" title="교회소식">
        <div className="section-body-container">
          {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
          {error && <p style={{ textAlign: "center" }}>에러 발생!</p>}
          {data && (
            <>
              <NoticeTable notices={currentPosts(data)}></NoticeTable>
              <WriteButton to="/write"></WriteButton>
            </>
          )}
          <Pagination
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            totalPosts={data !== null ? data!.length : 0}
            paginate={setCurrentPage}
          ></Pagination>
        </div>
      </Section>
    </>
  );
}
