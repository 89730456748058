import React from "react";
import "sass/ModalAlert.scss";
import Button from "./Button";

type ModalAlertProps = {
  title: string;
  body: string;
  buttonExist: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

ModalAlert.defaultProps = { buttonExist: true };

function ModalAlert({ title, body, buttonExist, onClick }: ModalAlertProps) {
  return (
    <div className="modal-alert-bg">
      <div className="modal-alert-container">
        <h3>{title}</h3>
        <p style={{ marginBottom: "30px" }}>{body}</p>
        {buttonExist && (
          <Button
            classType="complete"
            type="button"
            text="확인"
            onClick={onClick}
          ></Button>
        )}
      </div>
    </div>
  );
}

export default ModalAlert;
