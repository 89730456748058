import { Post } from "modules";
import NoticeItem from "./NoticeItem";
import "sass/Tables.scss";

type NoticeTableProps = {
  notices: Post[];
};

function NoticeTable({ notices }: NoticeTableProps) {
  return (
    <table className="notice-table">
      <thead>
        <tr className="post-header">
          {/* <th style={{ width: "8%" }}></th> */}
          <th style={{ width: "50%" }}>제목</th>
          <th style={{ width: "20%" }}>글쓴이</th>
          <th style={{ width: "10%" }}>등록일</th>
        </tr>
      </thead>
      <tbody className="notice-body">
        {notices.length > 0 &&
          notices.map((notice) => (
            <NoticeItem key={notice.id} notice={notice}></NoticeItem>
          ))}
      </tbody>
    </table>
  );
}

export default NoticeTable;
