import { Post, PostType } from "modules";
import {
  getPostAsync,
  getPostsAsync,
  GET_POST,
  GET_POSTS,
  postPostAsync,
  POST_POST,
} from "./posts";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import { getPostById, getPosts, postPost } from "api/posts";

function* getPostsSaga(action: ReturnType<typeof getPostsAsync.request>) {
  try {
    const posts: Post[] = yield call(getPosts, action.payload as PostType);
    yield put(getPostsAsync.success(posts));
  } catch (error: any) {
    yield put(getPostsAsync.failure(error));
  }
}

function* getPostSaga(action: ReturnType<typeof getPostAsync.request>) {
  try {
    const post: Post = yield call(
      getPostById,
      // action.payload.type as PostType,
      action.payload.id
    );
    yield put(getPostAsync.success(post));
  } catch (error: any) {
    yield put(getPostAsync.failure(error));
  }
}

function* postPostSaga(action: ReturnType<typeof postPostAsync.request>) {
  try {
    const result: boolean = yield call(postPost, action.payload); //payload : post

    yield put(postPostAsync.success(result));
  } catch (error: any) {
    console.log(error);
    yield put(postPostAsync.failure(error.response.data));
  }
}

export default function* postsSaga() {
  yield takeEvery(GET_POSTS.REQUEST, getPostsSaga);
  yield takeEvery(GET_POST.REQUEST, getPostSaga);
  yield takeEvery(POST_POST.REQUEST, postPostSaga);
}
