import classNames from "classnames";
import Button from "components/common/Button";
import React from "react";

type CommentWriteProps = {
  isReply: boolean;
  onClickCancel: (e: React.MouseEvent) => void;
  onClickSubmit: () => void;
};

function CommentWrite({
  isReply,
  onClickCancel,
  onClickSubmit,
}: CommentWriteProps) {
  // TODO: 로그인 유무에 따른 PlaceHolder
  return (
    <div
      className={classNames(
        isReply ? "comment-write-container reply" : "comment-write-container"
      )}
    >
      <textarea
        className="comment-write"
        placeholder={isReply ? "답글을 입력해주세요" : "댓글을 입력해주세요"}
        autoFocus={false}
      ></textarea>
      <div
        className={classNames(
          isReply
            ? "comment-button-container reply"
            : "comment-button-container"
        )}
      >
        {isReply && (
          <Button
            classType="cancel"
            text="취소"
            type="button"
            height="35px"
            onClick={onClickCancel}
          ></Button>
        )}
        <Button
          classType="normal"
          text="등록"
          type="button"
          onClick={onClickSubmit}
          height="35px"
        ></Button>
      </div>
    </div>
  );
}

CommentWrite.defaultProps = {
  onClickCancel: () => {},
};

export default CommentWrite;
