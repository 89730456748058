import { useEffect } from "react";

const { kakao }: any = window;

function KakaoMap() {
  var map: any;
  // 지도타입 컨트롤의 지도 또는 스카이뷰 버튼을 클릭하면 호출되어 지도타입을 바꾸는 함수입니다
  useEffect(() => {
    const latitude = 37.6123564;
    const longitude = 127.0222557;
    const markerPosition = new kakao.maps.LatLng(latitude, longitude);

    const container = document.getElementById("map");
    const options = {
      center: markerPosition,
      level: 5,
    };
    map = new kakao.maps.Map(container, options);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);

    // const infoWindowContent = "<div style='width:100%'>낙원교회</div>";

    // const infoWindow = new kakao.maps.InfoWindow({
    //   position: markerPosition,
    //   content: infoWindowContent,
    // });

    // infoWindow.open(map, marker);
  }, [map]);
  // 지도 확대, 축소 컨트롤에서 확대 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomIn = () => {
    map.setLevel(map.getLevel() - 1);
  };

  // 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomOut = () => {
    map.setLevel(map.getLevel() + 1);
  };

  return (
    <div id="map">
      <img
        className="button-zoom"
        alt=""
        style={{
          position: "absolute",
          width: "30px",
          zIndex: 10,
          padding:5,
          backgroundColor: "white",
          cursor: "pointer",
          right: "0",
          margin: "10px",
          top: "50px",
          border: "0.5px solid #ddd",
        }}
        src="/img/ic_plus.png"
        onClick={zoomIn}
      />
      <img
        className="button-zoom"
        alt=""
        style={{
          position: "absolute",
          width: "30px",
          padding:5,
          zIndex: 10,
          backgroundColor: "white",
          cursor: "pointer",
          right: "0",
          marginRight: "10px",
          border: "0.5px solid #ddd",
          top: "100px",
        }}
        src="/img/ic_minus.png"
        onClick={zoomOut}
      />
    </div>
  );
}

export default KakaoMap;
