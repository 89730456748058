import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "sass/Header.scss";

type MenuProps = SubMenuProps & {
  name: string;
  link: string;
  navActive: boolean;
};

type SubMenuType = {
  title: string;
  path: string;
};

export type SubMenuProps = {
  submenus: SubMenuType[];
  dropdownActive: boolean;
};

export const infoSubmenus: SubMenuType[] = [
  { path: "/info/church", title: "교회소개" },
  // { path: "/info/people", title: "섬기는 이" },
  { path: "/info/worship", title: "예배안내" },
  { path: "/info/location", title: "오시는 길" },
];

export const communitySubmenus: SubMenuType[] = [
  { path: "/community/1", title: "장년" },
  { path: "/community/2", title: "주일학교" },
  { path: "/community/3", title: "재건축" },
];

function Menu({ name, link, submenus, navActive }: MenuProps) {
  const [dropdownActive, setDropdownActive] = useState(false);

  return (
    <li className="nav-menu-item">
      {/* mobile */}
      {navActive && submenus.length > 0 ? (
        <>
          <div
            className={classNames("nav-menu-dropdown", {
              active: dropdownActive,
            })}
            onClick={() => {
              setDropdownActive(!dropdownActive);
            }}
          >
            <p className="nav-menu-title">{name}</p>
          </div>
          {
            <SubMenu
              submenus={submenus}
              dropdownActive={dropdownActive}
            ></SubMenu>
          }
        </>
      ) : (
        // desktop
        <>
          <Link to={link} reloadDocument>
            <p className="nav-menu-title">{name}</p>
          </Link>
          {submenus.length > 0 && (
            <SubMenu submenus={submenus} dropdownActive></SubMenu>
          )}
        </>
      )}
    </li>
  );
}

Menu.defaultProps = { submenus: [], dropdownActive: false };

function SubMenu({ submenus, dropdownActive }: SubMenuProps) {
  return (
    <ul className={classNames("nav-sub", { active: dropdownActive })}>
      {submenus.map((sub, i) => (
        <li className="nav-sub-item" key={i}>
          <Link to={sub.path} reloadDocument>
            <p>{sub.title}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
}

function Header() {
  const [navActive, setNavActive] = useState(false);

  return (
    <header className="header">
      <div className="header-container">
        <div className="nav-logo">
          <a href="/">
            <img alt="" src="/img/ic_logo.png" />
          </a>
        </div>
        <div
          className="nav-menu-button"
          onClick={() => {
            setNavActive(!navActive);
          }}
        >
          <img src={"/img/ic_menu.png"} alt=""></img>
        </div>
        <nav className={navActive ? "nav active" : "nav"}>
          <div className="header-login">
            <a
              href="https://www.youtube.com/@user-ko5rj2vw1j"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="header-img-link"
                alt=""
                src={"/img/ic_youtube.png"}
              ></img>
              <span style={{ fontWeight: 600 }}> 낙원 유튜브</span>
            </a>
            <a href="https://naver.com" target="_blank" rel="noreferrer">
              <img
                className="header-img-link"
                alt=""
                src={"/img/ic_band.png"}
              ></img>
              <span style={{ fontWeight: 600 }}> 낙원 밴드</span>
            </a>
            {/* <Link to="login" reloadDocument>
            <p>로그인</p>
          </Link>
          <Link to="signup" reloadDocument>
            <p>회원가입</p>
          </Link> */}
          </div>
          <ul className="nav-menu">
            <Menu name="홈" link="/" navActive></Menu>
            <Menu
              name="소개"
              link="/info/church"
              submenus={infoSubmenus}
              navActive
            ></Menu>
            <Menu name="교회소식" link="notice" navActive></Menu>
            {/* <Menu
              name="커뮤니티"
              link="community/1"
              submenus={communitySubmenus}
            ></Menu> */}
            <Menu name="주보" link="bulletin" navActive></Menu>
            <Menu name="예배영상" link="video" navActive></Menu>
          </ul>
        </nav>
      </div>
      <div className="header-bottom-divider"></div>
    </header>
  );
}

export default Header;
