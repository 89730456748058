import { asyncState, createAsyncReducer } from "lib";
import { createReducer } from "typesafe-actions";
import {
  getUserAsync,
  getUsersAsync,
  UserAction,
  UsersAction,
  UserState,
} from ".";
import {
  PostUserAction,
  postUserAsync,
  PostUserLoginAction,
  postUserLoginAsync,
  PostUserLoginState,
  PostUserState,
  UsersState,
} from "./users";

const initialState: UsersState = {
  users: asyncState.initial(),
};

export const users = createReducer<UsersState, UsersAction>(
  initialState
).handleAction(
  [getUsersAsync.request, getUsersAsync.success, getUsersAsync.failure],
  createAsyncReducer(getUsersAsync, "users")
);

export const user = createReducer<UserState, UserAction>({
  user: asyncState.initial(),
} as UserState).handleAction(
  [getUserAsync.request, getUserAsync.success, getUserAsync.failure],
  createAsyncReducer(getUserAsync, "user")
);

export const postUser = createReducer<PostUserState, PostUserAction>({
  registerSuccess: asyncState.initial(false),
} as PostUserState).handleAction(
  [postUserAsync.request, postUserAsync.success, postUserAsync.failure],
  createAsyncReducer(postUserAsync, "registerSuccess")
);

export const postUserLogin = createReducer<
  PostUserLoginState,
  PostUserLoginAction
>({
  token: asyncState.initial(),
} as PostUserLoginState).handleAction(
  [
    postUserLoginAsync.request,
    postUserLoginAsync.success,
    postUserLoginAsync.failure,
  ],
  createAsyncReducer(postUserLoginAsync, "token")
);
