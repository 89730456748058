import { KakaoMap, Section } from "components";
import { infoSubmenus } from "components/Layout/Header";
import "sass/InfoLocation.scss";

export function InfoLocationContainer() {
  return (
    <Section
      direction="Left"
      title="소개"
      submenus={infoSubmenus}
      subtitle="오시는 길"
    >
      <div className="location-container">
        <span>지도</span>
        <div className="location-map-container">
          <KakaoMap></KakaoMap>
        </div>
        <div className="location-info">
          <div>주소 : 서울특별시 강북구 삼양로 94</div>
        </div>
        <div className="location-transport">
          <span>대중교통</span>
          <div>버스 : 104, 109, 121, 152 [미아초교 정류장]</div>
          <div>마을버스 : 성북09 [파리바게트앞 정류장]</div>
          <div className="location-subway">
            <div>지하철 :&nbsp;</div>
            <div>
              <div> 미아사거리역 4번 출구</div>
              <div id="subway-multiline">
                길음역 8번 출구 (혹은 9번 출구 121번 버스 환승)
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
