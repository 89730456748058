export type PostType = "notice" | "senior" | "school" | "construct";

export const department = [
  "none",
  "senior",
  "youth",
  "middleHigh",
  "elementary",
  "kindergarten",
] as const;

export type Department = typeof department[number];

// export enum CommunityDivision {
//   "Senior" = 0,
//   "School" = 1,
//   "Construct" = 2,
// }

// Same as Server's DTO
export type User = {
  id: number;
  userId: string;
  userPassword: string;
  name: string;
  birth: Date | null;
  gender: string;
  phoneNumber: string;
  auth: number;
  department: string;
  profileImageUrl: string;
  createdDate: Date;
  updatedDate: Date;
};

export type LoginRequest = {
  userId: string;
  userPassword: string;
};

export type Post = {
  id: number;
  department: Department;
  postType: PostType;
  userId: number;
  // profileImg: string;
  // nickName: string;
  title: string;
  content: string;
  images: string; // {img1, img2, ...}
  comments: string; // {0,1...} : comment's ID array Comment[]
  createdDate: string; // yyyy-MM-dd
  updatedDate: string;
  // views: number;
};

export const defaultPost: Post = {
  id: -1,
  postType: "notice",
  department: "none",
  // nickName: "",
  userId: -1,
  // profileImg: "",
  title: "",
  content: "",
  images: "",
  // views: 0,
  comments: "",
  createdDate: "",
  updatedDate: "",
};

export type Comment = {
  id: number;
  postId: number;
  userId: string;
  profileImg: string;
  nickName: string;
  replyCommentId: number;
  content: string;
  createdDate: string;
  updatedDate: string;
  deleted: boolean;
};

export const defaultUser: User = {
  id: -1,
  userId: "",
  userPassword: "",
  name: "",
  birth: null,
  gender: "",
  phoneNumber: "",
  auth: 0,
  department: "",
  profileImageUrl: "",
  createdDate: new Date(),
  updatedDate: new Date(),
};

// Post Initial for Develop
export const dummyPost = (
  id: number,
  type: PostType,
  department: Department = "senior"
): Post => {
  return {
    id: id,
    postType: type,
    userId: 0,
    // profileImg: "",
    // nickName: `nickName${id}`,
    title: `제목입니다 ${id}`,
    content: `내용입니다\n ${id}`,
    images: "",
    createdDate: "",
    updatedDate: "",
    comments: "",
    // comments: [
    //   {
    //     id: 0,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     replyCommentId: -1,
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: false,
    //   },
    //   {
    //     id: 1,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     replyCommentId: -1,
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: false,
    //   },
    //   {
    //     id: 2,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     replyCommentId: 1,
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: false,
    //   },
    //   {
    //     id: 3,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     replyCommentId: 0,
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: false,
    //   },
    //   {
    //     id: 4,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     replyCommentId: 0,
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: true,
    //   },
    //   {
    //     id: 5,
    //     postId: id,
    //     profileImg: "",
    //     userId: "userID",
    //     replyCommentId: -1,
    //     content: "comment text",
    //     nickName: "comment Nick",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     deleted: true,
    //   },
    // ],
    // views: 12,
    department: department,
  };
};

export type Youtube = {
  items: {
    id: { kind: string; videoId: string };
    snippet: {
      title: string;
      description: string;
      publishTime: Date;
      thumbnails: {
        default: { url: string };
        high: { url: string };
        medium: { url: string };
      };
    };
  }[];
  nextPageToken: string;
  pageInfo: { resultsPerPage: number; totalResults: number };
};

// from YoutubeVideo
export type YoutubeVideo = {
  id: number;
  thumbnailUrl: string;
  url: string; // videoId
  title: string;
};

export type Bulletin = {
  id: number;
  thumbnailUrl: string;
  url: string;
  date: string;
};
