import classNames from "classnames";
import { Bulletin } from "modules";
import React, { MouseEventHandler, useState } from "react";
import "sass/BulletinPager.scss";

type BulletinGridProps = {
  bulletins: Bulletin[];
};

function BulletinPager({ bulletins }: BulletinGridProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const bulletin = bulletins[currentPage];
  const onClickArrow = (
    e: React.MouseEvent<HTMLImageElement>,
    direction: number
  ) => {
    e.preventDefault();
    const nextPage = currentPage + direction;
    if (nextPage < 0) {
      return;
    }
    setCurrentPage(currentPage + direction);
  };

  return (
    <div className="bulletin-pager-container">
      {bulletin && (
        <div className="bulletin-desktop">
          <div className="bulletin-pager-header">
            <img
              src="/img/ic_arrow.png"
              className={classNames(
                "bulletin-pager-arrow",
                "left",
                currentPage === 0 ? "disabled" : ""
              )}
              alt=""
              onClick={(e) => {
                onClickArrow(e, -1);
              }}
            ></img>
            <div className="bulletin-title">{bulletin.date}</div>
            <img
              src="/img/ic_arrow.png"
              className={classNames(
                "bulletin-pager-arrow",
                "right",
                currentPage === bulletins.length - 1 ? "disabled" : ""
              )}
              alt=""
              onClick={(e) => {
                onClickArrow(e, 1);
              }}
            ></img>
          </div>

          <a
            className="bulletin-pager-body"
            href={bulletin.url}
            target="_blank"
            rel="noreferrer"
          >
            <img className="bulletin-thumbnail" src={bulletin.url} alt=""></img>
          </a>
        </div>
      )}
      <div className="bulletin-mobile">
        {bulletins.map((bulletin, index) => {
          return (
            <a
              key={index}
              className="bulletin-mobile-item"
              href={bulletin.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="bulletin-mobile-title">{bulletin.date}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default BulletinPager;
