import React, { ChangeEvent, ReactEventHandler, SyntheticEvent } from "react";

type SelectProps = {
  options: string[];
  width: number;
  height: number;
  marginTop: number;
  padding: number;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

function Select({
  options,
  width,
  height,
  marginTop,
  padding,
  onChange,
}: SelectProps) {
  return (
    <select onChange={onChange} style={{ width, height, marginTop, padding }}>
      {options.map((option, index) => (
        <option key={index}>{option}</option>
      ))}
    </select>
  );
}

export default Select;
