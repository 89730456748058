import { asyncState, createAsyncReducer } from "lib";
import { createReducer } from "typesafe-actions";
import {
  BulletinsAction,
  BulletinsState,
  getBulletinsAsync,
} from "./bulletins";

const initialState: BulletinsState = {
  bulletins: asyncState.initial(),
};

// export const notices = createAsyncReducer(getNoticesAsync, "notices");
export const bulletins = createReducer<BulletinsState, BulletinsAction>(
  initialState
).handleAction(
  [
    getBulletinsAsync.request,
    getBulletinsAsync.success,
    getBulletinsAsync.failure,
  ],
  createAsyncReducer(getBulletinsAsync, "bulletins")
);
