import PostLayout from "components/post/PostLayout";
import { RootState } from "modules";
import { getPostAsync } from "modules/posts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "sass/Post.scss";

export function PostContainer() {
  const { postType, postId } = useParams();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.post.post
  );
  const dispatch = useDispatch();
  // const post = dummyPost(1, "COMMUNITY");

  useEffect(() => {
    const id = Number.parseInt(postId as string);
    console.log(id);
    if (id !== null && postType !== undefined) {
      dispatch(getPostAsync.request({ type: postType, id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
      {error && <p style={{ textAlign: "center" }}>에러 발생!</p>}
      {data && <PostLayout post={data}></PostLayout>}
    </>
  );
}
