import { API_SERVER_URL, POSTS_URL } from "api";
import axios from "axios";
import { Post, PostType } from "modules";

export async function getPosts(type: PostType) {
  // const t = type === "NOTICE" ? "notice" : "community";
  console.log("URL" + API_SERVER_URL);

  const response = await axios.get<Post[]>(POSTS_URL);
  console.log("TYPE" + type);
  // console.log("RESPONSE" + response.data);

  return response.data;
}

export async function getPostById(id: number) {
  const response = await axios.get<Post>(POSTS_URL + id);
  return response.data;
}

export async function postPost(post: Post) {
  const response = await axios.post<Post>(POSTS_URL, post);
  console.log("RESPONSE" + response.data);

  return response.data;
}

export async function putPost(id: number, post: Post) {
  const response = await axios.put<Post>(POSTS_URL + id, post);
  console.log("RESPONSE" + response.data);

  return response.data;
}

export async function deletePost(id: number) {
  const response = await axios.delete<Post>(POSTS_URL + id);
  console.log("RESPONSE" + response.data);

  return response.data;
}
