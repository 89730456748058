import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Section, Pagination } from "components";
import { RootState, YoutubeVideo } from "modules";
import "sass/Boards.scss";
import VideoGrid from "components/video/VideoGrid";
import { getVideosAsync } from "modules/videos";
import MetaTag from "SEOMetaTag";

export function VideoListContainer() {
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 10;
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.videos.videos
  );

  const indexOfLast = currentPage * videosPerPage;
  const indexOfFirst = indexOfLast - videosPerPage;

  function currentVideos(videos: YoutubeVideo[]) {
    let currentPosts = [];
    currentPosts = videos.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  }

  useEffect(() => {
    dispatch(getVideosAsync.request(0));
  }, [dispatch]);

  return (
    <>
      <MetaTag
        title="낙원교회 예배영상"
        description="서울시 강북구 미아동에 위치한 대한예수교 장로회 합동측 낙원교회입니다."
        keywords="강북구, 미아동, 삼양동, 낙원교회"
        url=""
      ></MetaTag>
      <Section direction="Right" title="예배영상">
        <div className="section-body-container">
          {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
          {error && <p style={{ textAlign: "center" }}>하루 요청량 초과</p>}
          {data && (
            <div className="video-container">
              <VideoGrid videos={currentVideos(data)}></VideoGrid>
              <div className="video-more-container">
                <a
                  className="video-more"
                  href="https://www.youtube.com/@user-ko5rj2vw1j/streams"
                >
                  <img alt="" src="/img/ic_youtube.png" />
                  지난 영상 보기
                </a>
              </div>
            </div>
          )}
          {/* <Pagination
          currentPage={currentPage}
          postsPerPage={videosPerPage}
          totalPosts={data !== null ? data!.length : 0}
          paginate={setCurrentPage}
        ></Pagination> */}
        </div>
      </Section>
    </>
  );
}
