import { AsyncState } from "lib";
import { Bulletin } from "modules/types";
import { ActionType, createAsyncAction } from "typesafe-actions";

export const GET_BULLETINS = {
  REQUEST: "GET_BULLETINS_REQUEST",
  SUCCESS: "GET_BULLETINS_SUCCESS",
  FAILURE: "GET_BULLETINS_FAILURE",
};

export const getBulletinsAsync = createAsyncAction(
  GET_BULLETINS.REQUEST,
  GET_BULLETINS.SUCCESS,
  GET_BULLETINS.FAILURE
)<null, Bulletin[], Error>();

export type BulletinsAction = ActionType<typeof getBulletinsAsync>;

export type BulletinsState = {
  bulletins: AsyncState<Bulletin[], Error>;
};
