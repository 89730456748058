import { AnyAction } from "redux";
import { AsyncActionCreatorBuilder, getType } from "typesafe-actions";

export type AsyncState<T, E = any> = {
  data: T | null;
  loading: boolean;
  error: E | null;
};

export const asyncState = {
  // 다음 코드는 화살표 함수에 Generic 을 설정 한 것입니다.
  initial: <T, E = any>(initialData?: T): AsyncState<T, E> => ({
    loading: false,
    data: initialData || null,
    error: null,
  }),
  load: <T, E = any>(data?: T): AsyncState<T, E> => ({
    loading: true,
    data: data || null,
    error: null,
  }),
  success: <T, E = any>(data: T): AsyncState<T, E> => ({
    loading: false,
    data,
    error: null,
  }),
  error: <T, E>(error: E): AsyncState<T, E> => ({
    loading: false,
    data: null,
    error: error,
  }),
};

type AnyAsyncActionCreator = AsyncActionCreatorBuilder<any, any, any>;

export function createAsyncReducer<S, AC extends AnyAsyncActionCreator>(
  asyncActionCreator: AC,
  key: string
) {
  return (state: S, action: AnyAction) => {
    const [request, success, failure] = transformToArray(
      asyncActionCreator
    ).map(getType);
    // return {
    //   [request]: (state: S) => ({
    //     ...state,
    //     [key]: asyncState.load(),
    //   }),
    //   [success]: (
    //     state: S,
    //     action: ReturnType<typeof asyncActionCreator.success>
    //   ) => ({
    //     ...state,
    //     [key]: asyncState.success(action.payload),
    //   }),
    //   [failure]: (
    //     state: S,
    //     action: ReturnType<typeof asyncActionCreator.failure>
    //   ) => ({
    //     ...state,
    //     [key]: asyncState.error(action.payload),
    //   }),
    // };
    switch (action.type) {
      case request:
        return { ...state, [key]: asyncState.load() } as S;
      case success:
        return { ...state, [key]: asyncState.success(action.payload) } as S;
      case failure:
        return { ...state, [key]: asyncState.error(action.payload) } as S;
      default:
        return state;
    }
  };
}

export function transformToArray(asyncActionCreator: AnyAsyncActionCreator) {
  const { request, success, failure } = asyncActionCreator;
  return [request, success, failure];
}

// export const handleAysncActions = (
//   type: string,
//   key: string,
//   keepData: any
// ) => {
//   const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
//   return (state:AsyncState<string,any>, action:AnyAction) => {
//     //update
//     switch (action.type) {
//       case type:
//         return {
//           ...state,
//           [key]: asyncState.load(keepData ? state.data : null),
//         };
//       case SUCCESS:
//         return { ...state, [key]: asyncState.success(action.payload) };
//       case ERROR:
//         return { ...state, [key]: asyncState.error(action.payload) };
//       default:
//         return state;
//     }
//   };
// };

// export const handleAysncActionsById = (type, key, keepData) => {
//   const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];
//   return (state, action) => {
//     const id = action.meta;
//     //update
//     switch (action.type) {
//       case type:
//         return {
//           ...state,
//           [key]: {
//             ...state[key],
//             [id]: reducerUtils.loading(
//               keepData ? state[key][id] && state[key][id].data : null
//             ),
//           },
//         };
//       case SUCCESS:
//         return {
//           ...state,
//           [key]: {
//             ...state[key],
//             [id]: reducerUtils.success(action.payload),
//           },
//         };
//       case ERROR:
//         return {
//           ...state,
//           [key]: {
//             ...state[key],
//             [id]: reducerUtils.error(action.payload),
//           },
//         };
//       default:
//         return state;
//     }
//   };
// };
//#endregion
