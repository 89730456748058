import axios from "axios";

// export const API_SERVER_URL = "https://222.111.65.87:7197/api";
export const API_SERVER_URL = "http://222.111.65.87:5197/api/";
export const POSTS_URL = API_SERVER_URL + "posts/";

export function initAxios() {
  axios.defaults.baseURL = API_SERVER_URL;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["Content-Type"] =
    "application/json; charset=utf8";
  axios.defaults.headers.post["Content-Type"] =
    "application/json; charset=utf8";
}

let accessToken = localStorage.getItem("accessToken");

const axiosDefault = axios.create({
  baseURL: API_SERVER_URL,
  headers: { ContentType: "application/json; charset=utf8" },
});

export const axiosPrivate = axios.create({
  baseURL: API_SERVER_URL,
  withCredentials: true,
  headers: { ContentType: "application/json; charset=utf8" },
});

axiosPrivate.interceptors.request.use(
  (req) => {
    accessToken = localStorage.getItem("accessToken") ?? null;
    // console.log("intercepter ran" + accessToken);
    // console.log(req.headers);

    if (accessToken && req.headers !== undefined) {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (err) {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        // AccessToken error (expired)
        originalConfig._retry = true; // prevent looping
        const refreshToken = originalConfig.headers["refreshToken"]; // get refreshToken from response cookie

        try {
          const refreshTokenRes = await axiosDefault.post("/refresh-token", {
            refreshToken: refreshToken,
          });
          const { accessToken } = refreshTokenRes.data;
          window.localStorage.setItem("accessToken", accessToken); // used in axiosPrivate

          return axiosPrivate(originalConfig); // retry api
        } catch (refreshErr: any) {
          if (refreshErr.response && refreshErr.response.data) {
            return Promise.reject(refreshErr.response.data);
          }
          return Promise.reject(refreshErr);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

// export function setJWTAuthorization(jwt: string) {
//   axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
// }
