import React from "react";
import Spinner from "./Spinner";
import "sass/LoadingProgressView.scss";

function LoadingProgressView() {
  return (
    <div className="loading-container">
      <Spinner></Spinner>
    </div>
  );
}

export default LoadingProgressView;
